import {
  Box,
  Button,
  Divider,
  Grid,
  IconButton,
  InputLabel,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Typography,
} from "@mui/material";
import { styled, Theme, CSSObject } from "@mui/material/styles";
import MuiDrawer from "@mui/material/Drawer";
import React, { useState } from "react";
import GridViewOutlinedIcon from "@mui/icons-material/GridViewOutlined";
import DoneIcon from "@mui/icons-material/Done";
import logoMenu from "../../assets/images/logo-menu.png";
import logoMenuCollapsed from "../../assets/images/logo-menu-collapsed.png";
import ArrowLeftIcon from "@mui/icons-material/ArrowLeft";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { useLocation, useNavigate } from "react-router-dom";
import { AppError, Paths } from "../../types/app";
import { logout } from "../../services/cognito";
import { useUserContext } from "../../contexts/user-context";
import LogoutIcon from "@mui/icons-material/Logout";
import sideMenuEdge from "../../assets/images/side-menu-edge.png";
import {
  hiringOutlineIcon,
  performanceManagementOutlineIcon,
  cultureValuesOutlineIcon,
  leadershipOutlineIcon,
  sltExecOutlineIcon,
  flexibleWorkOutlineIcon,
  parentalLeaveOutlineIcon,
  transparentPayOutlineIcon,
  policiesOutlineIcon,
  respectWorkOutlineIcon,
} from "../../assets/icons";
import { useStandards } from "../../contexts/StandardsProvider";
import InfoDialog from "../InfoDialog";
import { BootstrapInput } from "../BootstrapInput";
import { validateEmail } from "../../scenes/Login";
import { sendInvite } from "../../services/user-service";
import { useError } from "../../contexts/ErrorContext";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";

const drawerWidth = 255;

const mapIcons = {
  hiring: hiringOutlineIcon,
  performanceManagement: performanceManagementOutlineIcon,
  cultureValues: cultureValuesOutlineIcon,
  leadership: leadershipOutlineIcon,
  sltExec: sltExecOutlineIcon,
  flexibleWork: flexibleWorkOutlineIcon,
  parentalLeave: parentalLeaveOutlineIcon,
  transparentPay: transparentPayOutlineIcon,
  policies: policiesOutlineIcon,
  respectWork: respectWorkOutlineIcon,
};

const openedMixin = (theme: Theme): CSSObject => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(8)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(9)} + 1px)`,
  },
});

const DrawerHeader = styled("div")(({ theme }) => ({
  padding: "20px 15px 20px 15px",
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  boxSizing: "border-box",
  "&.menu-closed": {
    "& .drawer-header": {
      "& button": {
        padding: 0,
        width: "10px",
      },
      padding: "43px 9px 42.5px 9px",
    },
    "& img.logo-menu": {
      display: "none",
    },
    "& img.logo-menu-collapsed": {
      display: "block",
      float: "left",
    },
    "& .MuiListItem-root": {
      "& .MuiButtonBase-root": {
        height: "48px",
      },
    },
    "& .sub-menu": {
      margin: "0 12px",
      "& .MuiButtonBase-root": {
        padding: "12px 3px",
      },
      "& .MuiListItemText-root": {
        display: "none",
      },
      "& .MuiListItemIcon-root.action-icon": {
        display: "none",
      },
    },
    "& .logout-container": {
      marginLeft: 12,
    },
    "& .user-details": {
      display: "none",
    },
    "& .logout-menu": {
      "& button": {
        margin: 0,
      },
      float: "none",
    },
    "& .bottom-container": {
      "& .certified-icon": {
        display: "flex",
        padding: "10px",
        margin: "10px 30px 30px 10px",
      },
      "& .certified-btn": {
        display: "none",
      },
    },
  },
  "&.menu-closed.mobile": {
    "& .MuiDrawer-paper": {
      width: "65px",
      border: "none",
    },
    "& .MuiCollapse-root.MuiCollapse-vertical": {
      "& .MuiButtonBase-root": {
        padding: "10px 8px",
      },
    },
    "& .pixel-edge": {
      marginRight: "20px",
    },
  },
  "&.menu-open.mobile": {
    position: "absolute",
    "& .drawer-header": {
      minHeight: "auto",
    },
  },
  "& .drawer-header": {
    minHeight: "auto",
    "& .MuiButtonBase-root": {
      float: "right",
    },
  },
  "& .logout-menu": {
    "& button": {
      borderRadius: "10px",
      borderColor: "#4B5563",
    },
  },
  "& .MuiList-root": {
    "& .active": {
      "& svg": {
        color: "#FAAA0A",
      },
      "& .MuiTypography-body1": {
        fontWeight: 600,
      },
      color: "#FAAA0A",
    },
  },
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
  "& .MuiPaper-root": {
    backgroundColor: "#1A1C1E",
    color: "#F3F4F6",
    borderColor: "#fff",
    "& .MuiListItemIcon-root": {
      color: "#F3F4F6",
    },
    "& .MuiIconButton-root": {
      color: "#F3F4F6",
    },
    "& .MuiDivider-root": {
      borderColor: "#374151",
    },
  },
}));

type SideMenuProps = {
  isMobile: boolean;
};

export const SideMenu = ({ isMobile }: SideMenuProps): React.ReactElement => {
  const [openDialog, setOpenDialog] = useState(false);
  const userContext = useUserContext();
  const user = userContext.user;
  const userDomain = "@" + user?.emailId.split("@")[1];
  const { selectedStandard } = userContext;
  const { submittedStandards, unsubmittedStandards } = useStandards();
  const location = useLocation();
  const navigate = useNavigate();
  const { handleError } = useError();
  const pathname = location.pathname.split("/")[1];

  const [open, setOpen] = React.useState(isMobile ? false : true);
  const [emailId, setEmail] = useState("");
  const [link, setLink] = useState("");
  const [error, setError] = useState<string | undefined>(undefined);

  const handleDrawerToggle = () => {
    setOpen(!open);
  };

  const logoutUser = async () => {
    await logout(userContext.refreshToken!);
    userContext.clear();
    navigate(Paths.LOGIN);
  };

  const getFirstWord = (sentence: string) => {
    const match = sentence.match(/^[^ /@]+/);
    return match ? match[0] : sentence;
  };

  const menuClassNames = () => {
    let className = "";
    className += open ? "menu-open " : "menu-closed ";
    className += isMobile ? "mobile" : "desktop";
    return className;
  };

  const handleClickOpen = () => {
    setOpenDialog(true);
  };

  const handleClose = () => {
    setLink("");
    setEmail("");
    setError("");
    setOpenDialog(false);
  };

  const validateEmailAddress = () => {
    if (!validateEmail(emailId + userDomain)) {
      setError("Email is invalid");
      return false;
    }
    return true;
  };

  const handleSubmit = async () => {
    if (validateEmailAddress()) {
      setError(undefined);
      try {
        const { link } = await sendInvite(emailId + userDomain);
        setLink(link);
      } catch (e) {
        const error = e as AppError;
        if (error && error.message) {
          handleError(error.message);
        }
      }
    }
  };

  const drawer = (
    <>
      <DrawerHeader className="drawer-header">
        <Box component="img" alt="TEDI" className="logo-menu" src={logoMenu} />
        <Box
          component="img"
          alt="TEDI"
          className="logo-menu-collapsed"
          src={logoMenuCollapsed}
          sx={{
            display: "none",
          }}
        />
        <IconButton onClick={handleDrawerToggle} aria-label="menu toggle">
          {open ? (
            <ArrowLeftIcon fontSize="large" aria-label="menu open" />
          ) : (
            <ArrowRightIcon fontSize="large" aria-label="menu close" />
          )}
        </IconButton>
      </DrawerHeader>
      <Divider />
      <List>
        {["Dashboard", "Action Plans"].map((text, index) => (
          <ListItem
            key={text}
            disablePadding
            sx={{ display: "block" }}
            className={
              pathname === text.replace(" ", "-").toLowerCase() ? "active" : ""
            }
          >
            <ListItemButton
              sx={{
                minHeight: 48,
                justifyContent: open ? "initial" : "center",
                px: 2.5,
              }}
              onClick={() =>
                index % 2 === 0
                  ? navigate(Paths.DASHBOARD)
                  : navigate(Paths.ACTION_PLAN_HOME_PAGE)
              }
            >
              <ListItemIcon
                sx={{
                  minWidth: 0,
                  mr: open ? 3 : "auto",
                  justifyContent: "center",
                }}
              >
                {index % 2 === 0 ? <GridViewOutlinedIcon /> : <DoneIcon />}
              </ListItemIcon>
              <ListItemText primary={text} sx={{ opacity: open ? 1 : 0 }} />
            </ListItemButton>
          </ListItem>
        ))}
        <>
          <Box
            sx={{
              border: "1px solid #374151",
              color: "#F3F4F6",
              margin: "8px",
              borderRadius: "8px",
            }}
          >
            <List component="div" disablePadding className="sub-menu">
              {unsubmittedStandards.map((standard, index) => (
                <ListItemButton
                  sx={{ pl: 3 }}
                  key={index}
                  className={
                    selectedStandard?.title === standard.title ? "active" : ""
                  }
                  onClick={() =>
                    navigate(Paths.QUESTIONNAIRE, {
                      state: { standard: standard },
                    })
                  }
                >
                  <ListItemIcon sx={{ minWidth: "35px" }}>
                    <Box
                      component="img"
                      alt={standard.title + " icon"}
                      src={mapIcons[standard.iconPath]}
                    />
                  </ListItemIcon>
                  <ListItemText
                    primary={standard.title.replace(/@/g, "@ ")}
                    sx={{ marginTop: "4px", marginBottom: "3px" }}
                  />
                  <ListItemIcon
                    className="action-icon"
                    sx={{
                      minWidth: "24px",
                      background: "#374151",
                      padding: "3px",
                      borderRadius: "4px",
                    }}
                  >
                    <ArrowForwardIcon fontSize="small" />
                  </ListItemIcon>
                </ListItemButton>
              ))}
              {submittedStandards.map((standard, index) => (
                <ListItemButton
                  sx={{ pl: 3 }}
                  key={index}
                  onClick={() =>
                    navigate(Paths.ACTION_PLAN_HOME_PAGE + "/" + standard.id)
                  }
                  className={
                    selectedStandard?.title === standard.title
                      ? `active ${getFirstWord(standard.title.toLowerCase())}`
                      : ""
                  }
                >
                  <ListItemIcon sx={{ minWidth: "35px" }}>
                    <Box
                      component="img"
                      alt={standard.title + " icon"}
                      src={mapIcons[standard.iconPath]}
                    />
                  </ListItemIcon>
                  <ListItemText
                    primary={standard.title.replace(/@/g, "@ ")}
                    sx={{ marginTop: "4px", marginBottom: "3px" }}
                  />
                  <ListItemIcon
                    className="action-icon"
                    sx={{
                      minWidth: "24px",
                      background: "#374151",
                      padding: "4px",
                      borderRadius: "4px",
                      fontSize: "12px",
                    }}
                  >
                    {(standard.computedScore * 100).toFixed(2) + "%"}
                  </ListItemIcon>
                </ListItemButton>
              ))}
            </List>
          </Box>
        </>
      </List>
      <Box
        className="bottom-container"
        display="grid"
        sx={{ marginTop: "auto" }}
      >
        <Button
          role="button"
          variant="contained"
          className="certified-btn"
          onClick={handleClickOpen}
          sx={{
            mt: 0.5,
            mb: 1,
            ml: 2,
            mr: 2,
            pt: 1,
            pb: 1,
            borderRadius: "25px",
            fontSize: "16px",
            border: "1px solid #374151",
            background: "transparent",
            boxShadow: "none",
            color: "#F9FAFB",
            ":hover": {
              background: "#6B7280",
              borderColor: "#4B5563",
            },
          }}
        >
          Invite users
        </Button>

        <Box className="logout-container" sx={{ ml: 4, mb: 1, mr: 3 }}>
          <Box sx={{ float: "left" }} className="user-details">
            <Typography
              variant="body1"
              sx={{
                fontWeight: 700,
                fontSize: "13px",
                lineHeight: "19.5px",
              }}
            >
              {user?.firstName + " " + user?.lastName}
            </Typography>
            <Typography
              variant="body1"
              sx={{
                fontWeight: 500,
                fontSize: "12px",
                lineHeight: "18px",
                color: "#DAE6FF",
              }}
            >
              {user?.org?.name}
            </Typography>
          </Box>
          <Box className="logout-menu" sx={{ float: "right" }}>
            <IconButton
              size="medium"
              onClick={() => logoutUser()}
              aria-label="logout button"
              sx={{
                color: "#9CA3AF",
                border: "1px solid",
                marginLeft: "10px",
              }}
            >
              <LogoutIcon aria-label="logout icon" />
            </IconButton>
          </Box>
        </Box>
        <Box>
          <Box
            component="img"
            className="pixel-edge"
            alt={"pixel edge"}
            src={sideMenuEdge}
            sx={{ float: "right" }}
          />
        </Box>
      </Box>
    </>
  );

  return (
    <>
      <Drawer
        variant={"permanent"}
        open={open}
        ModalProps={{
          keepMounted: true, // Better open performance on mobile.
        }}
        className={menuClassNames()}
      >
        {" "}
        {drawer}
      </Drawer>
      <InfoDialog
        open={openDialog}
        handleClose={handleClose}
        title="Invite Users"
        showActions={link.length > 0 && emailId.length > 0 ? false : true}
        handleSubmit={handleSubmit}
        dialogInformation={
          link.length > 0 && emailId.length > 0 ? (
            <>
              <Typography>
                An email invitation has been sent to:{" "}
                <b>{emailId + userDomain}</b>
              </Typography>
              <Typography>
                And here is the sharing link if you need it:{" "}
                <IconButton
                  aria-label="copy"
                  onClick={() => navigator.clipboard.writeText(link)}
                >
                  <ContentCopyIcon />
                </IconButton>
              </Typography>
            </>
          ) : (
            <Grid
              item
              sx={{
                minWidth: "340px",
              }}
            >
              <InputLabel
                htmlFor="email"
                sx={{
                  textAlign: "left",
                  fontSize: "14px",
                  marginBottom: "3px",
                }}
              >
                Invitee work email
              </InputLabel>
              <BootstrapInput
                required
                id="email"
                name="email"
                autoComplete="email"
                error={!!error}
                onChange={(event) => setEmail(event.target.value)}
              />{" "}
              {userDomain}
              {error && (
                <InputLabel
                  htmlFor="email"
                  sx={{
                    textAlign: "left",
                    fontSize: "14px",
                    marginTop: "3px",
                    ...(error && { color: "#E3022C" }),
                  }}
                >
                  {error.toString()}
                </InputLabel>
              )}
            </Grid>
          )
        }
      />
    </>
  );
};
