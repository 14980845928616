import { ApiClient } from "./api-client";
import { AppException } from "../exceptions/exceptions";
import { CommonErrorCodes } from "../errors/common-error-codes";
import { IInsights, IInsightsByLevel, IInsightsByStandardByLevel } from "../types/insights";

export const getStats = async (): Promise<IInsights> => {
  const client = ApiClient.getInstance();
  try {
    const response = await client.get("/data/actionstats?groupBy=standard");
    return response.data as IInsights;
  } catch (e) {
    console.error("An error happened", e);
    throw new AppException(
      "There was an error fetching the insights. Please try again later.",
      CommonErrorCodes.API_ERROR,
    );
  }
};


export const getStatsByLevel = async (): Promise<IInsightsByLevel> => {
  const client = ApiClient.getInstance();
  try {
    const response = await client.get("/data/actionstats?groupBy=level");
    return response.data as IInsightsByLevel;
  } catch (e) {
    console.error("An error happened", e);
    throw new AppException(
      "There was an error fetching the insights. Please try again later.",
      CommonErrorCodes.API_ERROR,
    );
  }
};

export const getStatsByStandardByLevel = async (): Promise<IInsightsByStandardByLevel> => {
  const client = ApiClient.getInstance();
  try {
    const response = await client.get("/data/actionstats?groupBy=both");
    return response.data as IInsightsByStandardByLevel;
  } catch (e) {
    console.error("An error happened", e);
    throw new AppException(
      "There was an error fetching the insights. Please try again later.",
      CommonErrorCodes.API_ERROR,
    );
  }
};
