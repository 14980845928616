import { Widget } from "@typeform/embed-react";

type TypeFormProps = {
  formId: string;
  submitStandard: (formId: string, responseId: string) => void;
};

export const TypeForm = ({ formId, submitStandard }: TypeFormProps) => {
  return (
    <Widget
      id={formId}
      style={{ height: "calc(100vh - 150px)", margin: "30px 0px" }}
      onSubmit={(event) => submitStandard(event.formId, event.responseId)}
    />
  );
};
