import React, {
  createContext,
  useState,
  useEffect,
  useContext,
  ReactElement,
} from "react";
import { useError } from "./ErrorContext";
import { getStandards } from "../services/standards-service";
import { useUserContext } from "./user-context";
import { IStandards } from "../types/standard";
import { AppError } from "../types/app";

const StandardsContext = createContext<StandardsProviderType>({
  submittedStandards: [],
  unsubmittedStandards: [],
  refreshData: () => undefined,
});

type StandardsType = {
  submittedStandards: IStandards[];
  unsubmittedStandards: IStandards[];
};

type StandardsProviderType = {
  submittedStandards: IStandards[];
  unsubmittedStandards: IStandards[];
  refreshData: () => void;
};

export const StandardsProvider = ({ children }: { children: ReactElement }) => {
  const { user } = useUserContext();
  const [standards, setStandards] = useState<StandardsType>({
    submittedStandards: [],
    unsubmittedStandards: [],
  });
  const [hasFetchedData, setHasFetchedData] = useState(false);
  const { handleError } = useError();

  const getAllStandards = React.useCallback(async () => {
    try {
      const incompleteAssessments: IStandards[] = [];
      const completedAssessments: IStandards[] = [];
      const modules = await getStandards();
      modules.forEach((eachModule) => {
        if (eachModule.submittedBy !== undefined) {
          completedAssessments.push(eachModule);
        } else {
          incompleteAssessments.push(eachModule);
        }
      });
      setStandards({
        submittedStandards: completedAssessments,
        unsubmittedStandards: incompleteAssessments,
      });
      setHasFetchedData(true);
    } catch (e) {
      const error = e as AppError;
      if (error && error.message) {
        handleError(error.message);
      }
      setHasFetchedData(true);
    }
  }, [handleError]);

  useEffect(() => {
    if (!hasFetchedData && user?.org) {
      getAllStandards();
    }
  }, [getAllStandards, hasFetchedData, user?.org]);

  return (
    <StandardsContext.Provider
      value={{ ...standards, refreshData: getAllStandards }}
    >
      {children}
    </StandardsContext.Provider>
  );
};

export const useStandards = () => useContext(StandardsContext);
