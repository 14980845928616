import { useEffect, useState } from "react";
import { Box, useMediaQuery } from "@mui/material";
import { theme } from "../../assets/theme";
import { useUserContext } from "../../contexts/user-context";
import { getStats, getStatsByLevel, getStatsByStandardByLevel } from "../../services/insights-service";
import {
  IconPathLookupType,
  InsightByStandard,
  Stats,
  StatsWithScore,
} from "../../types/insights";
import { QuickLinks } from "../../components/QuickLinks";
import { InsightsStats } from "../../components/InsightsStats";
import { Benchmarking } from "../../components/Benchmarking";
import { StandardsGraph } from "../../components/StandardsGraph";
import { useStandards } from "../../contexts/StandardsProvider";
import { IconPathType } from "../../types/standard";
import { MaturityGraph } from "../../components/MaturityGraph";
import { AppError } from "../../types/app";
import { useError } from "../../contexts/ErrorContext";

type ColorsMapType = {
  [key in IconPathType]: string;
};

const colorsMap: ColorsMapType = {
  transparentPay: "#D3FF53",
  hiring: "#FF8960",
  performanceManagement: "#BAA3FF",
  flexibleWork: "#8AACD4",
  policies: "#E67A94",
  respectWork: "#8DDDFF",
  leadership: "#94D36D",
  sltExec: "#FF9900",
  parentalLeave: "#8EE4DA",
  cultureValues: "#FDE722",
};

export default function Insights() {
  const { handleError } = useError();
  const userContext = useUserContext();
  const { submittedStandards } = useStandards();
  const iconPaths: IconPathLookupType = {};
  submittedStandards.forEach((eachStandard) => {
    iconPaths[eachStandard.id] = eachStandard.iconPath;
  });

  // const [insights, setInsights] = useState<IInsights | undefined>(undefined);
  const [statsWithScore, setStatsWithScore] = useState<StatsWithScore | undefined>(undefined);
  const [standardInsights, setStandardInsights] = useState<InsightByStandard[]>(
    [],
  );
  const [iconPathLookup, setIconPathLookup] =
    useState<IconPathLookupType>(iconPaths);
  const [average, setAverage] = useState<number>(0);
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const getInsights = async () => {
    try {
      let leveledScore = 0;
      let possibleLeveledScore = 0;
      const stats = await getStats();
      const statsByLevel = await getStatsByLevel();
      const statsByStandardByLevel = await getStatsByStandardByLevel();

      Object.keys(statsByLevel.byLevel).map((levelKey) => {
        let multiplier = 0.75;
        let level = Number(levelKey);
        if (level === 1) {
          multiplier = 4;
        } else if (level === 2) {
          multiplier = 2;
        } else {
          multiplier = 0.75;
        }

        const levelScore = statsByLevel.byLevel[levelKey].done * multiplier;
        const possibleLScore = (statsByLevel.byLevel[levelKey].done + statsByLevel.byLevel[levelKey].todo) * multiplier;

        leveledScore += levelScore;
        possibleLeveledScore += possibleLScore;

        return true;
      })

      const levelStatsWithScore: StatsWithScore = { ...stats.total, score: leveledScore / possibleLeveledScore };

      const scores: number[] = [];
      const insightsByStandard: InsightByStandard[] = Object.keys(
        stats.byStandard,
      ).map((eachKey) => {
        const eachStandard = stats.byStandard[eachKey];
        const totalActionItems = eachStandard.todo + eachStandard.done;

        const byLevel = statsByStandardByLevel.byStandardByLevel[eachKey];

        let stdLeveledScore = 0;
        let stdLeveledPossibleScore = 0;
        Object.keys(byLevel).map((levelKey: string) => {
          if (levelKey === "name") {
            return true;
          }

          let multiplier = 0.75;
          const level = Number(levelKey);
          if (level === 1) {
            multiplier = 4;
          } else if (level === 2) {
            multiplier = 2;
          } else {
            multiplier = 0.75;
          }
  
          const levelStats = byLevel[levelKey] as Stats;
          const levelScore = levelStats.done * multiplier;
          const possibleLScore = (levelStats.done + levelStats.todo) * multiplier;
  
          stdLeveledScore += levelScore;
          stdLeveledPossibleScore += possibleLScore;
  
          return true;
        })

        const score = parseInt(
          (
            (stdLeveledScore / stdLeveledPossibleScore) *
            100
          ).toFixed(2),
        );
        scores.push(score);

        return {
          id: parseInt(eachKey),
          name: eachStandard.name.replace(/Management/g, "Mgmt."),
          color: iconPathLookup
            ? colorsMap[iconPathLookup[parseInt(eachKey)]]
            : "#D3FF53",
          score: score,
          stats: {
            done: eachStandard.done,
            total: totalActionItems,
          },
        };
      });
      // setInsights(stats);
      setStatsWithScore(levelStatsWithScore);
      setAverage(scores.reduce((total, score) => total += score, 0) / Object.keys(stats.byStandard).length);
      setStandardInsights(insightsByStandard);
    } catch (e) {
      const error = e as AppError;
      if (error && error.message) {
        handleError(error.message);
      }
    }
  };
  useEffect(() => {
    const iconPaths: IconPathLookupType = {};
    submittedStandards.forEach((eachStandard) => {
      iconPaths[eachStandard.id] = eachStandard.iconPath;
    });
    setIconPathLookup(iconPaths);
  }, [submittedStandards]);

  useEffect(() => {
    getInsights();
    userContext.set({
      selectedStandard: undefined,
    });
    // eslint-disable-next-line
  }, [iconPathLookup]);

  return (
    <Box
      component="main"
      sx={{ flex: 1, m: isMobile ? "15px 10px 30px 10px" : 5 }}
    >
      <InsightsStats actionItemStats={statsWithScore} />
      <MaturityGraph />
      <Benchmarking />
      <StandardsGraph
        standardInsights={standardInsights}
        average={average}
        iconPathLookup={iconPathLookup}
      />
      <QuickLinks />
    </Box>
  );
}
